import React from 'react'
import {
  Box,
  Flex,
  FormControl,
  IconButton,
  InputRightElement,
  Select,
  Text,
  useColorMode,
  useToast,
} from '@chakra-ui/react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'

import useForm from '@/hooks/useForm'
import formdata from '@/lib/constants/formData/userLogin'
import { useRouter } from 'next/router'
import CustomButton from '@/components/Button'
import Header from '@/components/Header'
import { TextInput } from '@/components/Input'
import LEColorConstants, {
  bgThemeColor,
  dashboardColor,
  primaryThemeColor,
  themeColor,
} from '@/lib/constants/colorConstants'
import { APP_NAME } from '@/lib/constants/appConstants'
import { axiosInstance, setCandidate as setCandidateLocal } from '@/lib/helpers'
import { stores } from '@/stores/index'

const Login = () => {
  const { colorMode } = useColorMode()
  const toast = useToast()
  const router = useRouter()

  const [showPassword, setShowPassword] = React.useState(false)
  const [enterPasword, setEnterPassword] = React.useState(false)
  const [step, setStep] = React.useState(1)
  const [candidate, setCandidate] = React.useState<any>()

  React.useEffect(() => {
    //Protection: Make sure org is set, before accessing any candidate page.
    if (!stores.organization.getOrganization()) {
      router.push('/404')
    }

    if (stores.candidate.getCandidate()) {
      router.push('/dashboard')
    }
  }, [])

  React.useEffect(() => {
    // Runs if form step changes to 2
    if (step === 2) {
      const newFormData = formdata
        .filter(form => form.level === 2)
        .reduce((prev, curr) => ({ ...prev, [curr.name]: '' }), {})

      handleModChange(newFormData)
    }
    if (step === 1) {
      inputTypes.confirmPassword = undefined
    }
  }, [step])

  const {
    handleChange,
    handleModChange,
    inputTypes,
    handleSubmit,
    errors,
    loading,
  } = useForm({
    inputs:
      step === 1
        ? formdata
            .filter(form => form.level === step)
            .slice(0, enterPasword ? 2 : 1)
        : formdata.filter(form => form.level === step),
    cb: async inputs => {
      if (step === 1) {
        // Here: Fri Oct 21, 2022 6:38AM got a call from my dad, that first son of grandpa died, dad ilorin
        const { data } = await axiosInstance.post('/auth/candidate/login', {
          ...inputs,
          organization_id: stores.organization.getOrganization()?.id,
        })

        if (data.data.requiredPasswordCreation) {
          setCandidate(data.data)
          setStep(2)
          return
        }

        if (data.message.includes('Candidate is found')) {
          setEnterPassword(true)
          return
        }

        stores.candidate.setCandidate(data.data)
        setCandidateLocal(data.data)

        toast({
          title: `Login successful, you are being redirecting to dashboard`,
          description: '',
          status: 'success',
          variant: 'top-accent',
          duration: 4000,
          isClosable: true,
        })

        router.push('/dashboard')
      }

      if (step === 2) {
        // create password
        if (inputTypes.password !== inputTypes.confirmPassword) {
          return toast({
            title: 'Validation Error',
            description: 'Passwords do not match',
            status: 'error',
            variant: 'top-accent',
            isClosable: true,
          })
        }

        await axiosInstance.post('/auth/candidate/create_password', {
          password: inputTypes.password,
          candidate_id: candidate?.candidate_id,
          organization_id: stores.organization.getOrganization()?.id,
        })

        toast({
          title: `Password successful created, you can login now`,
          description: '',
          status: 'success',
          variant: 'top-accent',
          duration: 6000,
          isClosable: true,
        })

        setStep(1)
        setEnterPassword(true)
      }
    },
  })

  return (
    <>
      <Header
        bgColor={primaryThemeColor[colorMode]}
        color={themeColor[colorMode]}
        open={open}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={dashboardColor[colorMode]}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          bg={bgThemeColor[colorMode]}
          w="full"
          borderRadius={['5px', '10px', '20px', '30px']}
          mt="3rem"
          pt="2rem"
          mx="2rem"
          mb="5rem"
        >
          <Text
            fontSize={['1rem', '1.5rem']}
            fontWeight="medium"
            textAlign="center"
            textTransform="capitalize"
          >
            {stores.organization.getOrganization()?.name}
          </Text>
          <Box px="0.5rem" pt="2.3rem">
            {formdata
              .filter(form => form.level === step)
              .slice(0, step === 1 ? (enterPasword ? 2 : 1) : 2)
              .map((data, i) => (
                <FormControl
                  w="full"
                  isInvalid={errors[data.name]}
                  key={`login_candidate_${i}`}
                >
                  <TextInput
                    name={data.name}
                    label={data.label}
                    handleChange={handleChange}
                    value={inputTypes[data.name]}
                    type={
                      data.type === 'password' && !showPassword
                        ? 'password'
                        : 'text'
                    }
                    error={errors[data.name] && data.errorMessage}
                    labelProps={{
                      fontSize: '1rem',
                      opacity: 0.6,
                    }}
                    formControlProps={{
                      _last: { pb: '1.563rem' },
                    }}
                    TextInputProps={{
                      variant: 'filled',
                      borderRadius: '5px',
                      h: { base: '2.8rem', md: '3.75rem' },
                      _focusWithin: {
                        border:
                          !errors[data.name] &&
                          `2px solid ${LEColorConstants.primary}`,
                      },
                    }}
                    placeholder={data.label}
                    TextInputElement={
                      data['type'] === 'password' ? (
                        <InputRightElement
                          h={{ base: '2.8rem', md: '3.75rem' }}
                          width="3.5rem"
                        >
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            aria-label="Toggle password"
                            _focus={{ boxShadow: 'none' }}
                            icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                          />
                        </InputRightElement>
                      ) : (
                        <Box />
                      )
                    }
                  />
                </FormControl>
              ))}

            {/* Select to choose campus */}
            {stores.organization.getOrganizations()?.length > 1 && step === 1 && (
              <FormControl
                w="full"
                isInvalid={errors['organization_id']}
                key={`login_candidate_organization`}
              >
                <Select
                  placeholder="Select Campus"
                  defaultValue={stores.organization.getOrganization()?.id}
                  onChange={e => {
                    const foundOrg = stores.organization
                      .getOrganizations()
                      .find(org => org.id === e.target.value)
                    if (!foundOrg) return
                    stores.organization.setOrganization(toJS(foundOrg))
                  }}
                  name="organization_id"
                  variant="filled"
                  borderRadius="5px"
                  h={{ base: '2.8rem', md: '3.75rem' }}
                  _focusWithin={{
                    border:
                      !errors['organization_id'] &&
                      `2px solid ${LEColorConstants.primary}`,
                  }}
                >
                  {stores.organization.getOrganizations().map(org => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            <CustomButton
              onClick={handleSubmit}
              isLoading={loading}
              fontSize="1rem"
              fontWeight="medium"
              h={{ base: '2.5rem', md: '3.12rem' }}
              borderRadius={'5px'}
              mt="1.188rem"
              maxW={'28.125rem'}
            >
              {step === 1 ? 'Submit' : 'Create'}
            </CustomButton>
          </Box>
          <Flex
            mt="6.25rem"
            py="1.2rem"
            justifyContent="center"
            alignItems="center"
          >
            &copy; {new Date().getFullYear() + ' ' + APP_NAME}
          </Flex>
        </Box>
      </Box>
    </>
  )
}

export default observer(Login)
